import shades from './shades';
import { basicColors } from './basicColors';

// It follows the basics of this spec: https://theme-ui.com/theme-spec#color
// With an advanced palette where all components relies on it

// Only role colors must be here to keep it themable!
export const light = {
  primary: '#1A73E8',
  primaryLight: '#87B4EF',
  primaryLightest: '#EEF4FC',
  // primary: '#5168ec',
  primaryHover: shades.blue[700],
  secondary: '#ff5a6e',
  secondaryHover: '#d94158',
  // Text
  heading: '#212121',
  text: '#667489',
  oppositeText: shades.grey[40],
  secondaryText: '#B8BFCC',
  textOnGradientBG: 'white',
  secondaryTextOnGradientBG: shades.grey[20],
  textOnPrimary: 'white',
  textOnSecondary: 'white',

  // Background / border / dividers
  background: shades.grey[5],
  oppositeBackground: '#131217',
  marketingBG: 'white',
  gradientBG: `linear-gradient(70deg,#5348FB 0%,#4193FF 100%)`,

  footer: shades.grey[5],
  bgPlaceholderPrimary: shades.grey[10],
  bgPlaceholderSecondary: shades.grey[30],
  backdrop: 'rgba(0,0,0,0.5)',
  muted: shades.grey[5],
  border: '#DDE1EC',
  inputBorder: shades.grey[30],
  interactiveBoxHover: 'rgba(0,0,0,0.05)',
  bg1: 'rgba(255,255,255, 1)',
  bg2: 'rgba(255,255,255, 1)',
  bg3: 'rgba(255,255,255, 1)',
  bg4: 'rgba(255,255,255, 1)',
  bg6: 'rgba(255,255,255, 1)',
  bg8: 'rgba(255,255,255, 1)',
  bg12: 'rgba(255,255,255, 1)',
  bg16: 'rgba(255,255,255, 1)',
  bg24: 'rgba(255,255,255, 1)',

  // Info color
  alert: {
    success: '',
    error: '',
    info: '',
    warning: ''
  },

  // Custom colors for components
  datatable: {
    header: shades.grey[30]
  },

  button: {
    cancel: shades.grey[30],
    cancelHover: shades.grey[40]
  },

  input: {
    text: shades.grey[80],
    bg: 'white',
    placeholder: shades.grey[50]
  }
};

export const dark = {
  primary: shades.blue[400],
  primaryHover: shades.blue[500],
  // primary: '#909EF2',
  // primaryHover: '#8091F1',
  secondary: '#ff5a6e',
  secondaryHover: '#d94158',

  // Text
  heading: 'white',
  text: shades.grey[30],
  oppositeText: '#667489',
  secondaryText: shades.grey[40],
  textOnPrimary: shades.grey[80],
  textOnSecondary: 'white',
  textOnGradientBG: 'white',

  // Background
  background: `#131217`,
  oppositeBackground: `white`,
  marketingBG: `#131217`,
  gradientBG: `linear-gradient(70deg,${shades.blue[400]} 0%,${shades.blue[700]} 100%)`,
  footer: 'rgba(255,255,255, 0.02)',
  bgPlaceholderPrimary: 'rgba(255,255,255, 0.05)',
  bgPlaceholderSecondary: 'rgba(255,255,255, 0.09)',
  backdrop: 'rgba(0,0,0,0.5)',
  muted: shades.grey[90],
  inputBorder: shades.grey[80],
  border: shades.grey[90],
  interactiveBoxHover: 'rgba(255,255,255,0.1)',

  bg1: 'rgba(255,255,255, 0.07)',
  bg2: 'rgba(255,255,255, 0.08)',
  bg3: 'rgba(255,255,255, 0.09)',
  bg4: 'rgba(255,255,255, 0.11)',
  bg6: 'rgba(255,255,255, 0.12)',
  bg8: 'rgba(255,255,255, 0.14)',
  bg12: 'rgba(255,255,255, 0.15)',
  bg16: 'rgba(255,255,255, 0.16)',
  bg24: 'rgba(255,255,255, 0.17)',
  // Info color
  alert: {
    success: '',
    error: '',
    info: '',
    warning: ''
  },

  // Custom colors for components
  button: {
    cancel: shades.grey[40],
    cancelHover: shades.grey[50]
  },

  input: {
    text: shades.grey[50],
    font: 1,
    bg: 'white',
    placeholder: shades.grey[50]
  }
};

export const colors = {
  ...basicColors,
  modes: {
    // dark,
    light
    // custom: {}
  }
};
