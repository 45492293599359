export const styles = {
  width: 40,
  height: 40
};

export const wrapperStyles = {
  borderRadius: 'circle',
  color: 'text',
  border: 1,
  borderColor: 'border'
};
