export const basicColors = {
  blue: '#2d7ff9',
  cyan: '#18bfff',
  teal: '#20d9d2',
  pink: '#f82b60',
  red: '#f5222d',
  orange: '#ff6f2c',
  yellow: '#fcb400',
  green: '#20c933',
  purple: '#8b46ff',
  dark: '#30374D'
};
