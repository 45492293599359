import { variants as textVariants } from './text';

export const styles = { textDecoration: 'none', color: 'inherit' };

export const variants = {
  primary: {
    color: 'primary'
  },
  nav: {
    ...textVariants.body1,
    display: 'inline-block',
    color: 'text',
    textDecoration: 'none',
    ':hover,:focus,.active': {
      color: 'primary'
    },
    '&.active': {
      color: 'primary',
      fontWeight: 1
    }
  }
};
