const whiteRGB = '255, 255, 255';

export default {
  blue: {
    100: `#e8f6ff`,
    200: `#bfe3ff`,
    300: `#96ceff`,
    400: `#6eb6ff`,
    500: `#4295f5`,
    600: `#1a73e8`,
    700: `#0c54c2`,
    800: `#023a9c`,
    900: `#002775`,
    1000: `#00184f`
  },
  grey: {
    90: '#212121',
    80: '#424242',
    70: '#616161',
    60: '#757575',
    50: '#9e9e9e',
    40: '#bdbdbd',
    30: '#e0e0e0',
    20: '#eeeeee',
    10: '#f5f5f5',
    5: '#fafafa'
  },
  white: {
    90: 'rgba(' + whiteRGB + ', 0.9)',
    80: 'rgba(' + whiteRGB + ', 0.8)',
    70: 'rgba(' + whiteRGB + ', 0.7)',
    60: 'rgba(' + whiteRGB + ', 0.6)',
    50: 'rgba(' + whiteRGB + ', 0.5)',
    40: 'rgba(' + whiteRGB + ', 0.4)',
    30: 'rgba(' + whiteRGB + ', 0.3)',
    20: 'rgba(' + whiteRGB + ', 0.2)',
    10: 'rgba(' + whiteRGB + ', 0.1)',
    5: 'rgba(' + whiteRGB + ', 0.5)'
  }
};
