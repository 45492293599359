export const styles = {
  px: 2,
  py: 1,
  fontSize: [3, 3, 2],
  borderRadius: 'radius',
  border: 1,
  borderColor: 'inputBorder',
  bg: 'input.bg',
  color: 'input.text',
  '::placeholder': {
    color: 'input.placeholder'
  },
  outline: 'none'
};

export const smallStyles = {
  fontSize: 1,
  px: 1,
  py: '4px'
};

export const variants = {
  passive: {
    border: '0',
    '-webkit-appearance': 'none',
    outline: 'none'
  }
};
