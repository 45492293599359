export const variants = {
  clickable: {
    cursor: 'pointer'
  },
  hover: {
    borderRadius: 'rounded',
    boxShadow: 'floating'
  },
  separator: {
    borderBottom: 1,
    borderColor: 'border'
  },
  border: {
    border: 1,
    borderColor: 'border'
  }
};
