import { variants as textVariants } from './text';

export const styles = {
  fontFamily: t => t.fonts.body,
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  px: 2,
  py: 1,
  cursor: 'pointer',
  minWidth: 100,
  borderRadius: 'radius',
  whiteSpace: 'nowrap',
  outline: 'none',
  ...textVariants.button,
  transition: t => t.transitions.curve.default
};

export const defaultVariant = 'primary';

export const smallStyles = {
  fontSize: 1,
  px: 1,
  py: '4px'
};

const textStyles = {
  border: 'none',
  boxShadow: 'none',
  bg: 'transparent'
};

const outlineStyles = {
  bg: 'transparent',
  boxShadow: 'inset 0 0 2px'
};

export const variants = {
  primary: {
    bg: 'primary',
    color: 'textOnPrimary',
    ':hover': {
      bg: 'primaryHover'
    }
  },

  secondary: {
    color: 'textOnSecondary',
    bg: 'secondary',
    ':hover': {
      bg: 'secondaryHover'
    }
  },

  cancel: {
    color: 'rgba(0,0,0,0.8)',
    bg: 'button.cancel',
    ':hover': {
      bg: 'button.cancelHover'
    }
  },

  text: {
    ...textStyles,
    color: 'text',
    bg: 'transparent',
    ':hover': {
      color: 'heading'
    }
  },

  primaryText: {
    ...textStyles,
    color: 'primary',
    outline: 'none',
    bg: 'transparent',
    ':hover': {
      color: 'primaryHover'
    }
  },

  secondaryText: {
    ...textStyles,
    color: 'secondary',
    bg: 'transparent',
    ':hover': {
      color: 'secondaryHover'
    }
  },

  cancelText: {
    ...textStyles,
    color: 'button.cancel',
    bg: 'transparent',
    ':hover': {
      color: 'button.cancelHover'
    }
  },

  primaryOutline: {
    ...outlineStyles,
    color: 'primary',
    ':hover': {
      bg: 'primary',
      color: 'white'
    },
    loading: {
      bg: 'primary',
      color: 'white'
    }
  },

  secondaryOutline: {
    ...outlineStyles,
    color: 'secondary',
    ':hover': {
      bg: 'secondary',
      color: 'white'
    },
    loading: {
      bg: 'secondary',
      color: 'white'
    }
  }
};
