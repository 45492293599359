export const elevation = mode => ({
  1: {
    bg: 'bg1',
    border: 1,
    borderColor: 'border',
    borderRadius: '10px'
  },
  2: {
    bg: 'bg2',
    ...(mode === 'light' ? { boxShadow: 'white' } : {}),
    border: 1,
    borderColor: 'border',
    borderRadius: '10px'
  },
  3: {
    bg: 'bg4',
    ...(mode === 'light' ? { boxShadow: 'white2' } : {}),
    border: 1,
    borderColor: 'border',
    borderRadius: '10px'
  },
  4: {
    bg: 'bg8',
    ...(mode === 'light' ? { boxShadow: 'white2' } : {}),
    border: 1,
    borderColor: 'border',
    borderRadius: '10px'
  }
});
