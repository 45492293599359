export const IS_PROD = true;

export const firebase = {
  apiKey: 'AIzaSyBh-0WEp3T4zVLYlWgXszUElzyUyvJy9T4',
  authDomain: 'app.churni.io',
  databaseURL: 'https://churni-prod.firebaseio.com',
  projectId: 'churni-prod',
  storageBucket: 'churni-prod.appspot.com',
  messagingSenderId: '683371397029',
  appId: '1:683371397029:web:40a21f102a9286fea7f24c',
  measurementId: 'G-RGEX437TGV',
  authClientID:
    '683371397029-bito19r49ah44ijn73p9gckbkct2n1fe.apps.googleusercontent.com'
};

export const sentry = {
  web: 'https://88666aa127904fa2a01599678deece0a@sentry.io/1880916'
};

export const googleFonts = {
  key: 'AIzaSyDmqKjh7DhKRSYrm-nvvfWRPoAokiqR09M'
};

export const urls = {
  dev: {
    website: 'http://localhost:8000',
    app: 'http://localhost:8001',
    cancel: 'http://localhost:8002',
    demo: 'http://localhost:8003'
  },
  hosting: {
    website: 'https://www.churni.io',
    app: 'https://app.churni.io',
    cancel: 'https://cancel.churni.io',
    demo: 'https://demo.churni.io'
  }
}[process.env.NODE_ENV === 'development' ? 'dev' : 'hosting'];

export const hubspot = {
  script: '//js.hs-scripts.com/7183938.js'
};

export const stripe = {
  publicKey: 'pk_test_Ljyfium1ha68MG22ERbiKmh800wcUL9Mcz'
};

export const heap = {
  id: '2499932880'
};

export const demo = {
  cancelConfirmationURL: 'https://demo.churni.io?status=canceled',
  saveReturnURL: 'https://demo.churni.io?status=saved',
  churniURL: 'https://app.churni.io/sessions',
  prod: 'app_m2UCG7IkfACOivhU',
  testing: 'app_ooZVhjBMFknbAnfE'
};

export const slack = {
  appID: 'AV4UY1JPP',
  clientID: '207709919990.990984052805',
  clientSecret: '4bb8d2656bb11bf86a0841d06a333dc7'
};

export const gtag = 'GTM-WG894TW';
