import { colors } from './colors';
import { fonts } from './fonts';
import { transitions } from './transitions';
import { shadows } from './shadows';
import { elevation } from './elevation';
import { variants as text } from './primitives/text';
import * as primitives from './primitives';
import * as components from './components';
import prism from './prism';

const convertInRem = (values, basePX = 16) =>
  values.map(value => `${value / basePX}rem`);

export default {
  initialColorMode: 'light',
  useColorSchemeMediaQuery: false,
  breakpoints: convertInRem([512, 768, 1024, 1367]),
  colors,
  fonts,
  fontSizes: convertInRem([10, 12, 14, 16, 20, 24, 32, 40, 48, 56, 64, 80, 96]),
  // px +
  lineHeights: convertInRem([
    18,
    20,
    22,
    24,
    28,
    32,
    40,
    48,
    56,
    64,
    72,
    88,
    104
  ]),
  fontWeights: [200, 400, 500, 700],
  text,
  shadows,
  space: convertInRem([
    0,
    8,
    16,
    24,
    32,
    40,
    48,
    56,
    64,
    96,
    128,
    160,
    192,
    224
  ]),
  radii: {
    square: 0,
    radius: 4,
    rounded: 10,
    circle: 99999
  },
  borders: [0, '1px solid', '2px solid', '5px solid'],
  zIndices: {
    menu: 10
  },
  transitions,
  primitives,
  components,
  elevation,
  styles: {
    pre: {
      ...prism
    }
  }
};
