import * as React from 'react';
import { FirebaseAppProvider } from 'reactfire';
import { FirebaseConfig } from './types';

export function FirebaseProvider(props: {
  children: React.ReactElement;
  config: FirebaseConfig;
}): React.ReactElement {
  const { children } = props;

  return (
    <FirebaseAppProvider firebaseConfig={props.config}>
      {children}
    </FirebaseAppProvider>
  );
}
