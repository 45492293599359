export const variants = {
  display1: {
    color: 'heading',
    fontSize: [8, 10],
    lineHeight: [8, 10],
    fontWeight: 3
    // letterSpacing: -1
  },
  display2: {
    color: 'heading',
    fontSize: [8, 9], // 56px
    lineHeight: [8, 9],
    fontWeight: 3
    // letterSpacing: -0.5
  },
  display3: {
    color: 'heading',
    fontSize: [7, 8], // 48px
    lineHeight: [7, 8],
    fontWeight: 3
    // letterSpacing: 0
  },
  display4: {
    color: 'heading',
    fontSize: [6, 7], // 40px
    lineHeight: [6, 7],
    fontWeight: 3
    // letterSpacing: 0
  },
  heading1: {
    color: 'heading',
    fontSize: 6, // 32px
    lineHeight: 6,
    fontWeight: 2
    // letterSpacing: 0.25
  },
  heading2: {
    color: 'heading',
    fontSize: 5, // 24px
    lineHeight: 5,
    fontWeight: 2
    // letterSpacing: 0
  },
  heading3: {
    color: 'heading',
    fontSize: 4, // 20px
    lineHeight: 4,
    fontWeight: 2
    // letterSpacing: 0.15
  },
  subtitle1: {
    color: 'heading',
    fontSize: 4, // 20 px
    lineHeight: 4,
    fontWeight: 2
    // letterSpacing: 0.15
  },
  subtitle2: {
    color: 'heading',
    fontSize: 3,
    lineHeight: 3,
    fontWeight: 2
    // letterSpacing: 0.5
  },
  subtitle3: {
    fontSize: 3,
    lineHeight: 3,
    fontWeight: 2
    // letterSpacing: 0.5
  },
  subtitle4: {
    fontSize: 2,
    lineHeight: 2,
    fontWeight: 2
    // letterSpacing: 0.5
  },
  body1: {
    fontSize: 3,
    lineHeight: 3,
    fontWeight: 1
    // letterSpacing: 0.5
  },
  body2: {
    fontSize: 2, // 14px
    lineHeight: 2, // 14px
    fontWeight: 1
    // letterSpacing: 0.25
  },
  body3: {
    fontSize: 1, // 12px
    lineHeight: 1,
    fontWeight: 1
    // letterSpacing: 0.25
  },
  badge: {
    fontSize: 1, // 14px
    lineHeight: 1, // 14px
    fontWeight: 1
    // letterSpacing: 0.25
  },
  button: {
    fontSize: 2, // 14px
    lineHeight: 2, // 14px
    fontWeight: 2,
    // textTransform: 'uppercase',
    letterSpacing: '0.03rem'
  },
  caption: {
    fontSize: 1, // 12px
    lineHeight: 1,
    // letterSpacing: 0.4,
    fontWeight: 1
  },
  overline1: {
    fontSize: 2, // 16px,
    lineHeight: 0,
    letterSpacing: 1.5,
    fontWeight: 1,
    textTransform: 'uppercase'
  },
  overline: {
    fontSize: 1, // 12px,
    lineHeight: 0,
    letterSpacing: '0.1rem',
    fontWeight: 2,
    textTransform: 'uppercase'
  },
  overline2: {
    fontSize: 1, // 12px,
    lineHeight: 0,
    letterSpacing: '0.05rem',
    fontWeight: 1,
    textTransform: 'uppercase'
  }
};
