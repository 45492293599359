export const transitions = {
  curve: {
    default: `cubic-bezier(0.4, 0, 0.2, 1)`
  },
  speed: {
    default: `250ms`,
    fast: `100ms`,
    slow: `350ms`
  }
};
